import React from "react";
import OrganizationSetup from "./sections/OrganizationSetup";
import SnapLogic from "./sections/Snaplogic";
import Confluence from "./sections/Confluence";

function Content() {
  return (
    <div className="custom-flex-basis">
      <OrganizationSetup />
      <SnapLogic />
      <Confluence />
    </div>
  );
}

export default Content;
