import React from "react";
import { Card } from "react-bootstrap";

const ContactUsCard = ({ title, content, Icon }) => {
  const contactUsIcon = <Icon size={100} color="#c12331" />;

  return (
    <Card className="custom-width-300">
      <Card.Header className="d-flex justify-content-center align-items-center px-5">
        {contactUsIcon}
      </Card.Header>
      <Card.Body className="d-flex flex-column justify-content-center align-items-center px-5">
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          {content}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ContactUsCard;
