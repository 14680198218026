import React from "react";
import style from "./index.module.css";
import appRegistration08 from "../../../assets/images/AppRegistration08.png";

function PeteSetup() {
  return (
    <div className={style.container}>
      <h4>Pete Setup</h4>
      <p>
        All these IDs are crucial for ‘Pete’ application. After successful login
        in ‘Pete’ SharePoint credentials need to be inserted.
      </p>
      <img
        src={appRegistration08}
        alt={appRegistration08}
        className="custom-margin-top30 d-block w-100"
      />
    </div>
  );
}

export default PeteSetup;
