import React, { useState, Fragment } from "react";
import { Col, FormCheck, Modal, Row } from "react-bootstrap";
import Button from "../Button";

function MultiplePDFDownloadModal({
  isOpen,
  modalData,
  onClose,
  onDownloadClick,
}) {
  const [selectedProjects, setSelectedProjects] = useState([]);

  const handleSelectAllClick = () => {
    if (selectedProjects.length === modalData.length) {
      setSelectedProjects([]);
    } else {
      setSelectedProjects([...modalData]);
    }
  };

  const handleCheckboxClick = (project) => {
    if (selectedProjects.includes(project)) {
      setSelectedProjects((prev) =>
        prev.filter((selectedProject) => selectedProject !== project)
      );
    } else {
      setSelectedProjects((prev) => [...prev, project]);
    }
  };

  const handleCloseClick = () => {
    setSelectedProjects([]);
    onClose();
  };

  const handleDownloadClick = () => {
    const encodedProjects = selectedProjects.map((project) =>
      encodeURIComponent(project)
    );

    onDownloadClick(encodedProjects.join(","));
    setSelectedProjects([]);
  };

  return (
    <Modal show={isOpen} onHide={handleCloseClick} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Download documentation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={10} className="text-end">
            Select all
          </Col>
          <Col md={2}>
            <FormCheck
              id="include-config-files"
              type="checkbox"
              checked={selectedProjects.length === modalData.length}
              onChange={handleSelectAllClick}
              className="ms-3"
            />
          </Col>

          {modalData.map((project) => (
            <Fragment key={`project-${project}${Math.random()}`}>
              <Col md={10} className="text-break">
                {project}
              </Col>
              <Col md={2}>
                <FormCheck
                  id="include-config-files"
                  type="checkbox"
                  checked={selectedProjects.includes(project)}
                  onChange={() => handleCheckboxClick(project)}
                  className="ms-3"
                />
              </Col>
            </Fragment>
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleDownloadClick}
          disabled={selectedProjects.length === 0}
        >
          Download
        </Button>
        <Button onClick={handleCloseClick}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MultiplePDFDownloadModal;
