import React from "react";

const UserTableHeader = () => {
  const headers = [
    { name: "First name", field: "firstName" }, //ok
    { name: "Last name", field: "lastName" },
    { name: "Email", field: "email" },
    { name: "Edit User", field: "edit" },
    { name: "Delete", field: "deleted" }, // ok
  ];

  return (
    <thead>
      <tr>
        {headers.map(({ name, field }) => (
          <th
            key={field}
          >
            {name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default UserTableHeader;
