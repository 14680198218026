import React from "react";
import style from "./index.module.css";

function ConfluenceToken() {
  return (
    <div className="mt-4">
      <h6>Confluence Token</h6>
      <ul>
        <li>
          Using the confluence account open your settings.
          <br />
          <img
            src="https://raw.githubusercontent.com/IWDGenerator/Media/main/SBS-ConfluenceToken-Settings.png"
            alt="Confluence token open settings"
            className={style["info-page-image"]}
          />
        </li>
        <li>
          Click on the Password
          <br />
          <img
            src="https://raw.githubusercontent.com/IWDGenerator/Media/main/SBS-ConfluenceToken-Password.png"
            alt="Confluence token click password"
            className={style["info-page-image"]}
          />
        </li>
        <li>
          Click on security and ‘Create and manage API tokens’.
          <br />
          <img
            src="https://raw.githubusercontent.com/IWDGenerator/Media/main/SBS-ConfluenceToken-APIToken.png"
            alt="Confluence token API token"
            className={style["info-page-image"]}
          />
        </li>
        <li>
          Click ‘Create API token’ and insert label for your token
          <br />
          <img
            src="https://raw.githubusercontent.com/IWDGenerator/Media/main/SBS-ConfluenceToken-CreateToken.png"
            alt="Confluence token create token"
            className={style["info-page-image"]}
          />
        </li>
        <li>
          Copy the value from the token. It is important to copy and save it
          somewhere else because you will not be able to see them later.
        </li>
      </ul>
    </div>
  );
}

export default ConfluenceToken;
