import React from "react";

function DowntimeAndMaintenance() {
  return (
    <div className="mt-5">
      <h4>Downtime and maintenance</h4>
      <p>
        Pete application is prompt to upgrades and new releases. Each release is
        planned to not disturb the running services, but it may happen the
        update to impact the application usage and running documentation. For
        each release and maintenance, the customer will be notified accordingly.
        Pete is consuming SnapLogic and Confluence APIs which in this case are
        not managed by Pete’s team and for any change in these APIs we cannot
        ensure that there will be no impact on our application. Our team will
        constantly monitor, work and test Pete application in order to provide
        best service possible.
      </p>
    </div>
  );
}

export default DowntimeAndMaintenance;
