import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { formatUrl, get } from "../../utils/api";
import UserProfile from "./UserProfile";
import Organization from "./Organization";
import { useSelectedOrganization } from "../../contexts/SelectedOrganizationContext";
import ChangePassword from "./ChangePassword";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState([]);
  const selectedOrganization = useSelectedOrganization();

  useEffect(() => {
    let isCancelled = false;

    const callApi = async () => {
      setIsLoading(true);

      const url = formatUrl("user/profile", {
        organizationName: selectedOrganization,
      });

      try {
        const response = await get(url);
        if (!isCancelled) {
          setUserProfile(response.data);
        }
      } catch (error) {
        if (!isCancelled) {
          toast.error(error.response?.data.name);
        }
      } finally {
        setIsLoading(false);
      }
    };

    callApi();

    return () => {
      isCancelled = true;
    };
  }, [selectedOrganization]);

  const currentUserInfo = userProfile[1];
  const currentUserOrganization = userProfile[0];

  const authMethod = localStorage.getItem("authMethod")

  return (
    <Container as={"main"} fluid className="px-5">
      <Row>
        <Col sm="12" lg="4">
          <UserProfile userInfo={currentUserInfo} isLoading={isLoading} />
          { authMethod !== 'OAUTH2' && <ChangePassword/>}
        </Col>
        <Col sm="12" lg="8">
          <Organization
            userInfo={currentUserInfo}
            organization={currentUserOrganization}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default Profile;
