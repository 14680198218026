import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';

const Spinner = ({ animation, size, variant, noMargin }) => (
  <div className={`d-flex w-100 ${noMargin ? '' : 'my-5 justify-content-center'}`}>
    <BootstrapSpinner
      animation={animation}
      role="status"
      size={size}
      variant={variant}
      area-hidden="true"
      className='spinner-color'
    >
      <span className="visually-hidden">Loading...</span>
    </BootstrapSpinner>
  </div>
);

Spinner.defaultProps = {
  animation: 'border',
  size: 'lg',
};

export default Spinner;
