import React from "react";
import { Accordion, Row, Col } from "react-bootstrap";
import Spinner from "../../components/Spinner";
import Title from "../../components/UI/Title";
import SnapLoginInfoForm from "./SnapLoginInfoForm";

const Organization = ({ userInfo, organization, isLoading }) => {
  return (
    <>
      <Title title="Organizations" />

      {isLoading ? (
        <Spinner />
      ) : (
        <Accordion defaultActiveKey={0} alwaysOpen>
          {organization && (
            <Accordion.Item
              key={organization.organization}
              eventKey={0}
              className="mb-5 bg-light"
            >
              <Accordion.Header>
                {organization.organization}
              </Accordion.Header>
              <Accordion.Body className='margin-left-20'>
                <Row>
                  <Col xs={4}>Company Name</Col>
                  <Col xs={8}>{organization.companyName}</Col>
                </Row>
                <Row>
                  <Col xs={4}>Website</Col>
                  <Col xs={8}>{organization.companyURL}</Col>
                </Row>
                <hr />
                <SnapLoginInfoForm
                  userInfo={userInfo}
                  organization={organization}
                />
              </Accordion.Body>
            </Accordion.Item>
          )}
        </Accordion>
      )}
    </>
  );
};

export default Organization;
