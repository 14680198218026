import React, { useState, useEffect } from 'react';
import { FormCheck } from 'react-bootstrap';
import { toast } from "react-toastify";
import Select from '../Select';
import Input from '../Input';
import Spinner from '../Spinner';
import style from './confluencestep.module.css';
import { formatUrl, get } from '../../utils/api';

const SharepointStep = ({
  selectedOrganization,
  selectedSharepointPage,
  newSharepointPage,
  shouldCreateDefaultPage,
  setSelectedSharepointPage,
  setNewSharepointPage,
  setShouldCreateDefaultPage,
}) => {
  const [sharepointPages, setSharepointPages] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const callApi = async () => {
    const apiUrl = formatUrl('sharepoint/pages', {
      organization: selectedOrganization,
    });

    setShowSpinner(true);

    try {
      const response = await get(apiUrl);
      if(response) {
        if (response.status === 200) {
          const firstElement = response.data.shift();
  
          setSharepointPages([
            firstElement,
            ...response.data.sort()
          ]);
        }
      }
    } catch (error) {
        toast.error(error.name);
    }

    setShowSpinner(false);
  };

  useEffect(() => {
    if (selectedOrganization) {
      callApi();
    }
  }, [selectedOrganization]);

  if (showSpinner) {
    return <Spinner />;
  }

  const handleSelectMainPage = (e) => {
    setSelectedSharepointPage(e.target.value);
  };

  const handleNewConfluencePage = (e) => {
    setNewSharepointPage(e.target.value);
  };

  const handleCheckDefaultPage = () => {
    setShouldCreateDefaultPage((prevState) => !prevState);
  };

  return (
    <div className="d-flex flex-column mt-5 mx-4">
      <div className="w-75">
        <h2 className={`${style.header}`}>Set Your Sharepoint Main Page</h2>
        <p className={`${style.text}`}>
          You can choose one of the already created Sharepoint main pages, create a new one or let the process create
          one for you by ticking the checkbox "Create Default Page". Sharepoint default page will be named same as the
          SnapLogic project space.
        </p>
        <div className="d-flex justify-content-between mt-5 mb-3">
          {shouldCreateDefaultPage === true ? null : (
            <Select
              id="choose-main-page"
              size="lg"
              options={sharepointPages}
              customClassNames={['custom_select']}
              label="Select Main Page"
              value={selectedSharepointPage}
              onChange={handleSelectMainPage}
              wrapperClasses={['custom_wrapper']}
              showDefaultValue
            />
          )}
          {shouldCreateDefaultPage === true ? null : (
            <>
              {selectedSharepointPage === 'Select Main Page' ? (
                <Input
                  id="create-main-page"
                  size="lg"
                  customClassNames={['custom_input']}
                  label="Create New Main Page"
                  placeholder="Create New Main Page"
                  value={newSharepointPage}
                  onChange={handleNewConfluencePage}
                  wrapperClasses={['custom_wrapper']}
                />
              ) : null}
            </>
          )}
        </div>
        <div className="d-flex justify-content-between mt-5 mb-3">
        {selectedSharepointPage === 'Select Main Page' ? (
          <FormCheck
            id="create-default-page"
            type="checkbox"
            label="Create Default Page"
            checked={shouldCreateDefaultPage}
            onChange={handleCheckDefaultPage}
          />
        ) : null}
        </div>
      </div>
    </div>
  );
};

export default SharepointStep;
