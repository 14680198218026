import React from "react";

function DataStorageAndProcessing() {
  return (
    <div className="mt-5">
      <h4>Data storage and processing</h4>
      <p>
        While interacting with SnapLogic, Pete is accessing and temporarily
        storing <strong>only the metadata</strong> for the SnapLogic assets.
        <strong>
          Pete does not run, validate or store any real execution{" "}
        </strong>{" "}
        from your pipelines. While the Confluence documentation is created, some
        metadata will be temporarily stored in a secured storage and is deleted
        once the process is completed or in case of failure. Same happens while
        generating documentation in PDF format except that in this case the
        final documentation is stored in Pete’s storage (West Europe) and can be
        downloaded directly from the web application. Retention of the PDF
        documentation is based on the package selected.{" "}
      </p>
    </div>
  );
}

export default DataStorageAndProcessing;
