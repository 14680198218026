import React from "react";
import { Link } from "react-router-dom";

function PrivacyNoticeVersionHistorySection() {
  return (
    <div className="w-75">
    <h4>Privacy Notice Version History</h4>
      <p>Current vesrion v1.0 published date: 11/02/2023</p>
      <Link to="/privacy-notice-history">Privacy Notice Version History</Link>
  </div>

  );
}

export default PrivacyNoticeVersionHistorySection;
