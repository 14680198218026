import React from "react";
import InfoPage from "../InfoPages";
import Content from "./Content";

function index() {
  return (
    <InfoPage title="How to setup Pete using Sharepoint?" subtitle="Step by step guide">
      <Content />
    </InfoPage>
  );
}

export default index;
