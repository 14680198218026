import React from "react";
import Sidebar from "./Sidebar";
import CenteredTitle from "../CenteredTitle";

function InfoPage({ title, subtitle, children }) {
  return (
    <div
      className="w-100 d-flex flex-column bg-light mt-3 px-4 pb-5 custom-rounded"
    >
      <CenteredTitle title={title} subtitle={subtitle} />
      <div className="d-flex custom-gap" >
        <Sidebar />
        {children}
      </div>
    </div>
  );
}

export default InfoPage;
