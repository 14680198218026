import { PublicClientApplication } from '@azure/msal-browser';
import { EventType } from '@azure/msal-browser';

let msal = null;

export const getMsalInstance = () => msal;

export const initializeMsal = async (config) => {
    msal = new PublicClientApplication(config);
    await msal.initialize()
    if (!msal?.getActiveAccount() && msal?.getAllAccounts().length > 0) {
        msal.setActiveAccount(msal?.getAllAccounts()[0]);
      }

      msal.enableAccountStorageEvents();

      msal.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          msal.setActiveAccount(account);
        }
      });
};
