import React from "react";

function OperationalData() {
    return (
        <div className="mt-5">
            <h4>Operational data </h4>
            We also store additional data which is not Personal Data but rather is operational and used for the normal functioning of the Service. You can find more information about this in the section ‘<a href="https://pete-va.iwconnect.com">Pete web application</a>’.
        </div>
    );
}

export default OperationalData;
