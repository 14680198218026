import React, { useState } from "react";
import { FormCheck } from "react-bootstrap";
import Input from "../Input";
import { useProjects } from "../../contexts/ProjectsContext";
import style from "./pipelineNameFilter.module.css";

function PipelineNameFilter() {
  const {
    startsWithFilter,
    setStartsWithFilter,
    containsFilter,
    setContainsFilter,
  } = useProjects();

  const [filterPipelines, setFilterPipelines] = useState(false);
  const handleCheckIncludeConfig = () => {
    setFilterPipelines((prev) => !prev);
  };

  const handleStartsWithFilterChange = (e) => {
    setStartsWithFilter(e.target.value.trimStart());
  };

  const handleContainsFilterChange = (e) => {
    setContainsFilter(e.target.value.trimStart());
  };

  return (
    <div className="w-50 d-flex flex-column mb-3 mt-4">
      <FormCheck
        id="filter-pipeline-by-names"
        type="checkbox"
        label="Filter pipelines by name"
        checked={filterPipelines}
        onChange={handleCheckIncludeConfig}
      />

      {filterPipelines ? (
        <div className="w-100 d-flex flex-column mt-3 ps-4">
          <Input
            id="filter-pipelines-starts-with"
            onChange={handleStartsWithFilterChange}
            value={startsWithFilter}
            size="md"
            placeholder="Pipeline name starts with..."
          />
          <Input
            id="filter-pipelines-contains"
            onChange={handleContainsFilterChange}
            value={containsFilter}
            size="md"
            placeholder="Pipeline name contains..."
          />

          <span className={style.text}>
            * Leave blank if you don't want to filter pipelines
          </span>
          <span className={style.text}>* Filters are case-sensitive</span>
          <span className={style.text}>* Use / as a separator for multiple values. Ex: Test/Copy/Manual</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PipelineNameFilter;
