import React, { useState, useEffect } from "react";
import style from './index.module.css';

const CustomCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 7000); 

    return () => clearInterval(intervalId);
  }, [currentIndex]); 

  return (
    <div className={style.sliderContainer}>
      <div className={`${style.slider} ${style[`translate-${currentIndex}`]}`}>
      {images.map((image, index) => (
          <div className={style.slide} key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;
