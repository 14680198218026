import { LogLevel } from "@azure/msal-browser";

const getBrowserInfo = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const msie11 = ua.indexOf("Trident/");
  const msedge = ua.indexOf("Edge/");
  const firefox = ua.indexOf("Firefox");
  const isIE = msie > 0 || msie11 > 0;
  const isEdge = msedge > 0;
  const isFirefox = firefox > 0;

  return { isIE, isEdge, isFirefox };
};

export const getMsalConfig = (config) => {
  const { isIE, isEdge, isFirefox } = getBrowserInfo();

  return {
    auth: {
    clientId: config.client_id,
    authority:
      `https://login.microsoftonline.com/${config.tenant_id}`,
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
      allowNativeBroker: false,
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) return;
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              break;
            case LogLevel.Info:
              console.info(message);
              break;
            case LogLevel.Verbose:
              console.debug(message);
              break;
            case LogLevel.Warning:
              console.warn(message);
              break;
            default:
              break;
          }
        },
      },
    },
  };
};

export const loginRequest = {
  scopes: ["User.Read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
