import React from "react";
import InfoPageHeader from "../../components/InfoPages/Header";
import HowToSetupContent from "../../components/HowToSetupContent/index";

function HowToSetup() {
  return (
    <div className="w-75 mx-auto">
      <InfoPageHeader />
      <HowToSetupContent />
    </div>
  );
}

export default HowToSetup;
