import React from "react";
import style from "./index.module.css";

function OrganizationSetup() {
  return (
    <div>
      <h4>Organization Setup</h4>
      <p>
        Please share bellow company and organization details with Pete’s team:{" "}
      </p>
      <ul className={style["dashed-list"]}>
        <li>Company name</li>
        <li>SnapLogic organization name</li>
        <li>
          Users (email address, First name, Last name) to be added to Pete’s
          application
        </li>
      </ul>
      <p>
        Once the above info is provided and Pete’s team confirms everything
        looks good, we can continue with the SnapLogic setup.
      </p>
    </div>
  );
}

export default OrganizationSetup;
