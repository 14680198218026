import React, { useState } from "react";
import { toast } from "react-toastify";
import { Stack, Spinner } from "react-bootstrap";

import { useSelectedOrganization } from "../../contexts/SelectedOrganizationContext";
import { post } from "../../utils/api";

import Button from "../../components/Button";
import TextArea from "../../components/Input";
import Select from "../../components/Select";
import Title from "../../components/UI/Title";

const ContactUsForm = () => {
  const selectedOrganization = useSelectedOrganization();
  const [note, setNote] = useState("");
  const [department, setDepartment] = useState("Support");
  const [isSendingData, setIsSendingData] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (note.trim().length <= 10) {
      toast.error("Please Enter Your message!");
      return;
    }

    setIsSendingData(true);
    try {
      const response = await post("user/contact-us", {
        organization: selectedOrganization,
        department: department,
        note: note,
      });
      toast.success(response.data[0]);
      setDepartment("Support");
      setNote("");
    } catch (error) {
      toast.error(error.response.data.name || "There was some error!");
    } finally {
      setIsSendingData(false);
    }
  };

  return (
    <>
      <Title title="Contact Us" />
      <form onSubmit={handleSubmit}>
        <Select
          className="mb-4"
          size="lg"
          options={["Support", "Sales"]}
          customClassNames={["custom_select"]}
          value={department}
          label="Please select an option"
          onChange={(e) => setDepartment(e.target.value)}
        />
        <TextArea
          textarea
          placeholder="Note"
          label="Note"
          name="note"
          id="note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          customClassNames={["custom_textarea"]}
        />

        <Stack direction="horizontal" className="mt-5">
          <Button disabled={isSendingData} size="lg" type="submit">
            Send
          </Button>
          {isSendingData ? (
            <Spinner animation="border" variant="primary" className="mx-4" />
          ) : null}
        </Stack>
      </form>
    </>
  );
};

export default ContactUsForm;
