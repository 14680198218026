import React from "react";
import InfoPageHeader from "../../components/InfoPages/Header";
import HowToSetupContentUsingSP from "../../components/HowToSetupContentUsingSP/index";

function HowToSetupUsingSharepoint() {
  return (
    <div className="w-75 mx-auto">
      <InfoPageHeader />
      <HowToSetupContentUsingSP />
    </div>
  );
}

export default HowToSetupUsingSharepoint;
