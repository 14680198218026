import React from "react";
import HowPeteConnects from "./sections/HowPeteConnects";
import SnaplogicConnection from "./sections/SnaplogicConnection";
import ConfluenceConnection from "./sections/ConfluenceConnection";
import UserRegistration from "./sections/UserRegistration";
import DataStorageAndProcessing from "./sections/DataStorageAndProcessing";
import HostingLocation from "./sections/HostingLocation";
import DowntimeAndMaintenance from "./sections/DowntimeAndMaintenance";
import SharepointConnection from "./sections/SharepointConnection";

function Content() {
  return (
    <div className="custom-flex-basis">
      <HowPeteConnects />
      <SnaplogicConnection />
      <ConfluenceConnection />
      <SharepointConnection />
      <UserRegistration />
      <DataStorageAndProcessing />
      <HostingLocation />
      <DowntimeAndMaintenance />
    </div>
  );
}

export default Content;
