import React, { useState, useEffect } from "react";
import "./SharepointCookieBanner.css";
import CookieSettingsModal from "./CookieSettingsModal";
import { useCookieConsent } from "../../contexts/CookieConsentContext";
import { Link, useLocation } from "react-router-dom";

const SharepointCookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const { setCookieConsent, setFunctionalCookiesConsent, cookieConsent } =
    useCookieConsent();
  const location = useLocation();

  useEffect(() => {
    const checkConsent = () => {
      const consent = localStorage.getItem("cookieConsent");
      // Show the banner if consent has not been given and the user is navigating to the login page
      const consentCookie = !consent || consent === 'false'
      const locationPath = location.pathname || location.pathname === "/login";
      if (consentCookie && locationPath) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    checkConsent();
  }, [location, cookieConsent]);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
  };

  const handleAcceptModal = (functionalConsent) => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
    setIsSettingsVisible(false);
    setCookieConsent(true);
    setFunctionalCookiesConsent(functionalConsent);
  };

  const handleSettingsClick = () => {
    setIsSettingsVisible(true);
    setIsVisible(false);
  };

  const handleSettingsClose = () => {
    setIsSettingsVisible(false);
    setIsVisible(true);
  };

  if (!isVisible && !isSettingsVisible) return null;

  return (
    <>
      {isVisible && (
        <div className="cookie-consent-modal">
          <div className="cookie-content">
            <h6>Cookie Use for Enhanced Security</h6>
            <p>
              Our site uses essential cookies to streamline your sign-in
              process, enable secure Microsoft account authentication, and
              safeguard your sign-up with reCAPTCHA. By clicking ACCEPT or by
              continuing to navigate the site, you consent to our use of these
              necessary cookies for an optimized and secure user experience. See
              our Privacy Policy
              <Link to="/privacy-notice"> here.</Link> Additional information can be
              found in the{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleSettingsClick();
                }}
              >
                cookie settings.
              </a>
            </p>
          </div>
          <div className="cookie-actions">
            <button onClick={handleAccept} className="cookie-btn accept">
              Accept All Cookies
            </button>
          </div>
        </div>
      )}
      {isSettingsVisible && (
        <CookieSettingsModal
          onClose={handleSettingsClose}
          onAccept={handleAcceptModal}
        />
      )}
    </>
  );
};

export default SharepointCookieBanner;
