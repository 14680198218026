import React from "react";

function DataSecurity() {
  return (
    <div className="mt-5">
      <h4>Data security</h4>
      <p>
        Pete takes all technical and organizational measures to ensure that your data is protected from accidental loss or disclosure, destruction or misuse. To achieve this goal, we have envisioned and implemented a number of procedures and policies that are controls to protect your data and your privacy. These controls for personal data security are correlated or complement each other with the controls from the certified Information Security Management System (ISMS) which is implemented in accordance with ISO 27001:2013. You can find more information for the certification on the following link: {" "}
        <a
          href="https://iwconnect.com/iso-certified/"
          target="_blank"
          rel="noreferrer"
        >
          https://iwconnect.com/iso-certified/
        </a>{" "}
      </p>
      <p>
        The technical and organizational security measures are at various levels, including encryption and authentication tools. Your personal information is stored on systems in a secure network and is only available to a limited number of people with exclusive access rights to such systems. Employees who have access to your personal data are obliged to treat your personal data as confidential and to take care of their security.
      </p>
      <p>
        When we share your data with third parties who are also Personal Data Processors, we ensure that your data is stored securely and in accordance with the PDP Law.
      </p>
      <p>Third parties must take technical and organizational measures to ensure the security of your personal data. When selecting Processors, we pay special attention to the technical and organizational measures that Processors take for the security of personal data. </p>
    </div>
  );
}

export default DataSecurity;
