import React from "react";
import style from "./index.module.css";
import appRegistration05 from "../../../assets/images/AppRegistration05.png";
import appRegistration06 from "../../../assets/images/AppRegistration06.png";
import appRegistration07 from "../../../assets/images/AppRegistration07.png";

function SetAPIPermissions() {
  return (
    <div className={style.container}>
      <h4>Set API Permissions</h4>
      <ol className={style["numbered-list"]}>
        <li>
          Once finished with setting up the Client Secret, navigate to the API
          Permissions page (left navigation panel, beneath Manage).
        </li>
        <li>
          Select <strong>Add a Permission.</strong>
        </li>
        <li>
          Beneath the Microsoft APIs tab, select{" "}
          <strong>Microsoft Graph.</strong>
        </li>
        <li>
          What type of permission does your application require? Select{" "}
          <strong>Application Permissions.</strong>
        </li>
        <li>In the dropdown selections, choose all permission levels.</li>
        <li>
          Once the Permission levels have been selected, click{" "}
          <strong>Add Permissions.</strong>
        </li>
        <li>
          In the API Permissions page, click{" "}
          <strong>Grant Admin consent for {"Tenant Name"}.</strong>
        </li>
        <li>
          Click on <strong>Yes</strong> for the consent confirmation popup.
          <img
            src={appRegistration05}
            alt={appRegistration05}
            className="custom-margin-top30 d-block w-100"
          />
          <img
            src={appRegistration06}
            alt={appRegistration06}
            className="custom-margin30 d-block w-100"
          />
          <img
            src={appRegistration07}
            alt={appRegistration07}
            className="custom-margin30 d-block w-100"
          />
        </li>
      </ol>
    </div>
  );
}

export default SetAPIPermissions;
