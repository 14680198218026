import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import TextArea from "./Input/index";
import ReCAPTCHA from "react-google-recaptcha";

import validator from "validator";
import { toast, ToastContainer } from "react-toastify";
import Button from "./Button";
import { post } from "../utils/api";

const SignUp = () => {
  // const [spinner, setSpinner] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [note, setNote] = useState("");

  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleClose = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setCompany("");
    setNote("");
    setEmailError("");
    setFirstNameError("");
    setLastNameError("");
    setCompanyError("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSigninFormSubmit = async (event) => {
    event.preventDefault();

    const isEmailValid = validator.isEmail(email);
    const isFirstNameValid = firstName.trim().length >= 2;
    const isLastNameValid = lastName.trim().length >= 2;
    const isCompanyValid = company.trim().length >= 1;

    if (!isEmailValid) {
      setEmailError("Enter valid Email!");
    } else {
      setEmailError("");
    }

    if (!isFirstNameValid) {
      setFirstNameError("Enter your first name");
    } else {
      setFirstNameError("");
    }

    if (!isLastNameValid) {
      setLastNameError("Enter your last name");
    } else {
      setLastNameError("");
    }

    if (!isCompanyValid) {
      setCompanyError("Enter your company name");
    } else {
      setCompanyError("");
    }

    if (!captchaValue) {
      toast.error("Please verify you are not a robot.");
      return;
    }

    if (isEmailValid && isFirstNameValid && isLastNameValid && isCompanyValid) {
      setIsSending(true);

      try {
        const response = await post("user/signup", {
          email,
          firstName,
          lastName,
          company,
          note,
          captchaResponse: captchaValue,
        });

        toast.success(response?.data);
        setEmail("");
        setFirstName("");
        setLastName("");
        setCompany("");
        setNote("");
        setShow(false);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.name);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          setEmail("");
          setFirstName("");
          setLastName("");
          setCompany("");
          setNote("");
          setShow(false);
        }
      }

      setIsSending(false);
    }
  };

  return (
    <>
      <div>
        <span className="custom-font-size">
          Don't have an account?
        </span>
        <span className="sign-up ms-1" onClick={handleShow}>
          Sign Up
        </span>
      </div>
      <Modal show={show} centered onHide={handleClose} backdrop="static">
        <Modal.Header closeButton className="border-top-0">
          <h3 className="display-4">Sign Up</h3>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="form-group mb-3">
              <label
                htmlFor="email"
                className="custom-control-label text-left mb-1 custom-font-size"
              >
                Email*
              </label>
              <Input
                id="email"
                type="email"
                placeholder="Enter Your Email Address"
                className="form-control px-4"
                value={email}
                onChange={handleEmailChange}
              />
              <span className="error">{emailError}</span>
            </div>
            <div className="form-group mb-3">
              <label
                htmlFor="inputName"
                className="custom-control-label custom-font-size"
              >
                First Name*
              </label>
              <Input
                id="inputName"
                type="text"
                placeholder="Enter Your First Name"
                className="form-control px-4"
                value={firstName}
                onChange={handleFirstNameChange}
              />
              <span className="error">{firstNameError}</span>
            </div>
            <div className="form-group mb-3">
              <label
                htmlFor="inputLName"
                className="custom-control-label text-left mb-1 custom-font-size"
              >
                Last Name*
              </label>
              <Input
                id="inputLName"
                type="text"
                placeholder="Enter Your Last Name"
                className="form-control  px-4"
                value={lastName}
                onChange={handleLastNameChange}
              />
              <span className="error">{lastNameError}</span>
            </div>
            <div className="form-group mb-3">
              <label
                htmlFor="inputCompany"
                className="custom-control-label text-left mb-1 fs-6 custom-text"
              >
                Company*
              </label>
              <Input
                id="inputCompany"
                type="text"
                placeholder="Enter Your Company"
                className="form-control px-4"
                value={company}
                onChange={handleCompanyChange}
              />
              <span className="error">{companyError}</span>
            </div>

            <TextArea
              textarea
              placeholder="Enter Your Note"
              label="Note"
              name="note"
              id="note"
              value={note}
              onChange={handleNoteChange}
              customClassNames={["custom_textarea"]}
              errorText="some eror"
            />
          </Form>
          <div className="form-group">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={(value) => setCaptchaValue(value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="padding-bottom-20">
          <Button
            disabled={isSending}
            type="submit"
            onClick={handleSigninFormSubmit}
            className="w-100"
          >
            Sign Up
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default SignUp;
