import React from "react";
import style from "./index.module.css";

function HowPeteConnects() {
  return (
    <div className="w-100">
      <h4>How Pete connects to SnapLogic and Confluence</h4>
      <div className="w-100 d-flex flex-column align-items-center">
        <div>
          <img
            src="https://raw.githubusercontent.com/IWDGenerator/Media/main/PeteSystemsConnections.png"
            alt="How Pete connects to SnapLogic and Confluence"
            className={style["info-page-image"]}
          />
        </div>
        <div>
          <img
            src="https://raw.githubusercontent.com/IWDGenerator/Media/main/UsersAndConnections.png"
            alt="Users and Connections"
            className={style["info-page-image"]}
          />
        </div>
      </div>
    </div>
  );
}

export default HowPeteConnects;
