import React, { useState } from "react";
import style from "./index.module.css";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import { Col, Row } from "react-bootstrap";

function SharepointConnection() {
  const [isSharepointConnectionOpen, setIsSharepointConnection] =
    useState(false);

  const toggleSharepointConnection = () => {
    setIsSharepointConnection(!isSharepointConnectionOpen);
  };

  return (
    <div className="mt-3">
      <Row>
        <Col md={11} className="d-flex align-items-center">
          <button
            onClick={toggleSharepointConnection}
            className="btn btn-text p-0 custom-button-style cursor-pointer"
          >
            <h4>Sharepoint Connection</h4>
          </button>
        </Col>
        <Col
          md={1}
          onClick={toggleSharepointConnection}
          className="text-center d-flex align-items-center justify-content-center"
        >
          <div className="mt-n3">
            {isSharepointConnectionOpen ? <ChevronDown /> : <ChevronUp />}
          </div>
        </Col>
      </Row>
      {isSharepointConnectionOpen ? (
        <Row>
          <p>
            To connect to Sharepoint, you need a valid Sharepoint organization
            and an account with access to your Sharepoint organization. Create
            the App Registration within Entra and enter the following details in
            Pete's web application:
          </p>
          <p>
            <strong>Sharepoint Information we collect and store</strong>
          </p>
          <ul className={style["disc-list"]}>
            <li>Sharepoint Site</li>
            <li>Sharepoint Tenant Name</li>
            <li>Tenant ID</li>
            <li>Sharepoint Client ID</li>
            <li>Sharepoint Client Secret</li>
          </ul>
          <p>These details are securely stored in Pete's database.</p>
          <p>
            Please note that the documentation that will be created in
            Sharepoint will be created by the Sharepoint user that has been
            setup at the time of the documentation creation. You can also change
            this user in the Sharepoint settings of the application but please
            be aware that it will overwrite the previously stored settings, to
            be more precise, the new settings entered will be stored instead of
            the old setting.
          </p>
        </Row>
      ) : null}
    </div>
  );
}

export default SharepointConnection;
