import React from "react";
import style from "./index.module.css";

function ConfluenceSpaceKey() {
  return (
    <div className="mt-4">
      <h6>Confluence Space Key</h6>
      <ul>
        <li>
          Open you Confluence space and copy the part of the URL that comes
          after …/spaces/ until the next ‘/’
        </li>
        <li>Example: IWDG</li>
      </ul>

      <img
        src="https://raw.githubusercontent.com/IWDGenerator/Media/main/SBS-ConfluenceSpaceKey.png"
        alt="Confluence space key"
        className={style["info-page-image"]}
      />
    </div>
  );
}

export default ConfluenceSpaceKey;
