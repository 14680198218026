import React, { useState, useEffect } from 'react';
import { FormCheck } from 'react-bootstrap';
import { toast } from "react-toastify";
import Select from '../Select';
import Input from '../Input';
import Spinner from '../Spinner';
import style from './confluencestep.module.css';
import { formatUrl, get } from '../../utils/api';

const ConfluenceStep = ({
  selectedOrganization,
  selectedConfluencePage,
  newConfluencePage,
  shouldCreateDefaultPage,
  setSelectedConfluencePage,
  setNewConfluencePage,
  setShouldCreateDefaultPage,
  setShouldCreateProjectSpacePage,
  shouldCreateProjectSpacePage
}) => {
  const [confluencePages, setConfluencePages] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const callApi = async () => {
    const apiUrl = formatUrl('confluence/pages', {
      organization: selectedOrganization,
    });

    setShowSpinner(true);

    try {
      const response = await get(apiUrl);
      if(response) {
        if (response.status === 200) {
          // Sort elements ascending, but make sure the first element is always "Select Main Page"
          const firstElement = response.data.shift();
  
          setConfluencePages([
            firstElement,
            ...response.data.sort()
          ]);
        }
      }
    } catch (error) {
        toast.error(error.name);
    }

    setShowSpinner(false);
  };

  useEffect(() => {
    if (selectedOrganization) {
      callApi();
    }
  }, [selectedOrganization]);

  if (showSpinner) {
    return <Spinner />;
  }

  const handleSelectMainPage = (e) => {
    setSelectedConfluencePage(e.target.value);
  };

  const handleNewConfluencePage = (e) => {
    setNewConfluencePage(e.target.value);
  };

  const handleCheckDefaultPage = () => {
    setShouldCreateDefaultPage((prevState) => !prevState);
  };

  const handleProjectSpacePage = () => {
    setShouldCreateProjectSpacePage((prevState) => !prevState);
  };

  return (
    <div className="d-flex flex-column mt-5 mx-4">
      <div className="w-75">
        <h2 className={`${style.header}`}>Set Your Confluence Main Page</h2>
        <p className={`${style.text}`}>
          You can choose one of the already created Confluence main pages, create a new one or let the process create
          one for you by ticking the checkbox "Create Default Page". Confluence default page will be named same as the
          SnapLogic project space.
        </p>
        <div className="d-flex justify-content-between mt-5 mb-3">
          {shouldCreateDefaultPage === true ? null : (
            <Select
              id="choose-main-page"
              size="lg"
              options={confluencePages}
              customClassNames={['custom_select']}
              label="Select Main Page"
              value={selectedConfluencePage}
              onChange={handleSelectMainPage}
              wrapperClasses={['custom_wrapper']}
              showDefaultValue
            />
          )}
          {shouldCreateDefaultPage === true ? null : (
            <>
              {selectedConfluencePage === 'Select Main Page' ? (
                <Input
                  id="create-main-page"
                  size="lg"
                  customClassNames={['custom_input']}
                  label="Create New Main Page"
                  placeholder="Create New Main Page"
                  value={newConfluencePage}
                  onChange={handleNewConfluencePage}
                  wrapperClasses={['custom_wrapper']}
                />
              ) : null}
            </>
          )}
        </div>
        <div className="d-flex justify-content-between mt-5 mb-3">
        {selectedConfluencePage === 'Select Main Page' ? (
          <FormCheck
            id="create-default-page"
            type="checkbox"
            label="Create Default Page"
            checked={shouldCreateDefaultPage}
            onChange={handleCheckDefaultPage}
          />
        ) : null}
        {shouldCreateDefaultPage === true ? null : (
          <>
            {selectedConfluencePage === "Select Main Page" ? (
              <FormCheck
                id="create-project-space-page"
                type="checkbox"
                label="Create Project Space Page"
                checked={shouldCreateProjectSpacePage}
                onChange={handleProjectSpacePage}
              />
            ) : null}
          </>
        )}
        </div>
      </div>
    </div>
  );
};

export default ConfluenceStep;
