import React from "react";
import style from "./index.module.css";

function UsingPeteMail() {
  return (
    <div className="mt-4">
      <h6>Using Pete’s email</h6>
      <p>
        Pete team will provide email (sampleemail@iwdg.mk) that you can add on
        your SnapLogic organization (same as adding regular user with UI access)
        and grant Read only permissions to the projects you want to document.
      </p>
      <ul className={style["disc-list"]}>
        <li>Step 1: Add the provided email to your organization. </li>
        <li>
          Step 2: Grant Read only to the projects (and project spaces) you want
          to document.
        </li>
      </ul>
    </div>
  );
}

export default UsingPeteMail;
