import React from "react";
import InfoPageHeader from "../../components/InfoPages/Header";
import {  Table } from "react-bootstrap";
import CenteredTitle from "../../components/CenteredTitle";
import { getBlob } from "../../utils/api";

function PrivacyNoticeVersionHistoryPage() {
  const versions = [
    {
      version: "1.0",
      releaseDate: "2024",
      linkText: "Pete-Privacy-Notice-v1",
      name: "Pete-Privacy-Notice-v1.pdf",
      notes: "Version 1.0",
    }
  ]

  const download = (file, fileName) => {
    const blob = new Blob([file], {type: "application/pdf"});
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  };

  const handleDownloadDocument = async (e, name) => {
    e.preventDefault(); 
    const url = `privacy-notice?document=${name}`;

    const response = await getBlob(url);
    download(response, `${name}`);
  };


  return (
    
  <div className="w-75 mx-auto ">
    <InfoPageHeader />
    <CenteredTitle title={'Privacy Notice Version History'} />

    <div className="container mt-5">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Version</th>
            <th>Release Date</th>
            <th>Link</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {versions.map((version, index) => (
            <tr key={index}>
              <td>{version.version}</td>
              <td>
                  {version.releaseDate}
              </td>
              <td>
                <a href="#" onClick={(e) => handleDownloadDocument(e, version.name)} target="_blank" rel="noopener noreferrer">
                  {version.linkText}
                </a>
              </td>
              <td>{version.notes}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  </div>

  );
}

export default PrivacyNoticeVersionHistoryPage;
