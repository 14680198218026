import React from "react";
import Button from "../Button";
import LogoImage from "../../assets/images/IWDGPeteLogo.png";
import { useNavigate } from "react-router-dom";

function InfoPageHeader() {
  const navigate = useNavigate();
  const isAuth = sessionStorage.getItem('userAuthenticated')
  const button = isAuth === 'true' ? 'Back' : 'Login'

  const handleButtonClick = () => {
    if (isAuth === 'true') {
      navigate('/new')
    } else {
      navigate("/login")
    }
  };
  
  console.log('isAuth', isAuth, typeof isAuth)
  return (
    <div className="w-100 d-flex justify-content-between align-items-center mt-4">
      <img src={LogoImage} width={100} height={60} />
        <div>
          <Button onClick={handleButtonClick}>{button}</Button>
      </div>
    </div>
  );
}

export default InfoPageHeader;
