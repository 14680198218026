import React, { useState } from "react";
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import { durationConvert, timeConvert, statusConvert } from "./tableHelpers";
import PDFTableHeader from "./PDFTableHeader";
import MultiplePDFDownloadModal from "../../../components/PDFStep/MultiplePDFDownloadModal";
import { getBlob, post } from "../../../utils/api";
import { toast } from "react-toastify";
import { Trash } from "react-bootstrap-icons";
import { useSelectedOrganization } from "../../../contexts/SelectedOrganizationContext.js";
import Modal from "../../../components/Modal/index.js";
import Button from "../../../components/Button/index.js";

const PDFDashboardTable = ({ projectsData }) => {
  const selectedOrganization = useSelectedOrganization();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalExecutionId, setModalExecutionId] = useState("");
  const [isDownloadingId, setIsDownloadingId] = useState("");
  const [item, setItem] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handlePDFLinkClick = async (
    executionId,
    projectName,
    pdfDeletedTime
  ) => {
    if (isDownloadingId !== "") {
      return;
    }
    try {
      if (pdfDeletedTime) {
        toast.error("Pdf has been deleted. Retention period has expired");
        return;
      }
      const projects = projectName.split(",");

      if (projects.length === 1) {
        const encodedProject = encodeURIComponent(projects[0]);
        const url = `zipdocumentation?SLExecutionID=${executionId}&SLProject=${encodedProject}`;

        setIsDownloadingId(executionId);

        const response = await getBlob(url);

        download(response, `${projects[0]}.zip`);
      } else {
        setModalExecutionId(executionId);
        setIsModalOpen(true);
        setModalData(projects);
      }
    } catch (error) {
      setModalExecutionId("");
      console.log(error);
      toast.error(error.response);
    }
  };

  const download = (file, fileName) => {
    const blob = new Blob([file]);

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
    setIsDownloadingId("");
  };

  const handleDeleteClick = async (executionId, pdfDeletedTime, status) => {
    setIsDeleting(true)
    switch (status) {
      case 1:
        if (pdfDeletedTime) {
          toast.error("Pdf has already been deleted.");
        } else {
          setShowModal(false);
          if (!isDeleting) {
            toast.success("Deleting PDF documentation is in progress");
            const response = await post("removepdf", {
              SLExecutionID: executionId,
              SLOrganization: selectedOrganization,
            });
            if (response.status === 200) {
              toast.success("Pdf deleted successfully");
              setIsDeleting(false)
            } else {
              toast.error("Failed to delete the PDF.");
            }
          }
        }
        break;

      case 0:
        toast.error("Pdf generation is in progress. You cannot delete it.");
        break;

      case 2:
        toast.error("Pdf is in failed status. You cannot delete it.");
        break;

      default:
        break;
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalExecutionId("");
  };

  const handleMultipleDownload = async (projects) => {
    setIsModalOpen(false);
    const url = `zipdocumentation?SLExecutionID=${modalExecutionId}&SLProject=${projects}`;

    setIsDownloadingId(modalExecutionId);

    const response = await getBlob(url);

    download(response, "documentation.zip");

    setModalData([]);
    setModalExecutionId("");
  };

  const onDeleteClick = (item) => {
    setItem(item);
    !item.pdfDeletedTime && item.status === 1 && setShowModal(true);
  };

  const modalButtons = (
    <div className="d-flex justify-content-between align-items-end w-100">
      <Button id="cancelButton" onClick={() => setShowModal(false)}>
        Cancel
      </Button>
      <Button
        id="create-new-organization-summary"
        onClick={() =>
          handleDeleteClick(item.ID, item.pdfDeletedTime, item.status)
        }
      >
        Confirm
      </Button>
    </div>
  );

  return (
    <>
      <Table hover responsive className="mt-4 font-14px">
        <PDFTableHeader />
        <tbody>
          {projectsData &&
            projectsData.map((item) => (
              <tr
                key={item["ID"]}
              >
                <td>
                  {item["projectName"].length > 20
                    ? item["projectName"].slice(0, 21)
                    : item["projectName"]}
                  ;
                  {item["projectName"].length > 20 && (
                    <OverlayTrigger
                      rootClose
                      trigger="click"
                      key={item["ID"]}
                      placement={"top"}
                      overlay={
                        <Popover id={`popover-${item["ID"]}`}>
                          <Popover.Header
                            className="popover-header"
                          >
                            {item["projectName"]} Project Name
                          </Popover.Header>
                          <Popover.Body>
                            <small>{item["projectName"]}</small>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <span className="p-0 cursor-pointer custom-button-style">
                        ...
                      </span>
                    </OverlayTrigger>
                  )}
                </td>
                <td className="text-center">
                  {item.status === 0 ? <span>Generating PDF...</span> : <></>}
                  {item.status === 1 &&
                    isDownloadingId !== item.ID &&
                    !item.pdfDeletedTime ? (
                    <a
                      href="#"
                      onClick={() =>
                        handlePDFLinkClick(
                          item.ID,
                          item.projectName,
                          item.pdfDeletedTime
                        )
                      }
                    >
                      Download PDF
                    </a>
                  ) : (
                    <></>
                  )}
                  {item.status === 1 && isDownloadingId === item.ID ? (
                    <a href="#">Downloading PDF...</a>
                  ) : (
                    <></>
                  )}
                  {item.status === 2 ? (
                    <span>PDF generation failed</span>
                  ) : (
                    <></>
                  )}
                  {item.pdfDeletedTime && item.status === 1 ? (
                    <span>PDF deleted</span>
                  ) : (
                    <></>
                  )}
                </td>
                <td className="text-center">
                  <Trash
                    className={
                      item.pdfDeletedTime || item.status !== 1
                        ? "disabled-style"
                        : "enabled-style"
                    }
                    onClick={() => onDeleteClick(item)}
                    disabled={item.pdfDeletedTime && item.status !== 1}
                  />
                </td>
                <td className="text-center">
                  {item["ID"].slice(0, 10)}
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    key={item["ID"]}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-${item["ID"]}`}>
                        <Popover.Header
                          className="fs-6 fw-bold custom-bg"
                        >
                          {item["projectName"]} Execution ID
                        </Popover.Header>
                        <Popover.Body>
                          <small>{item["ID"]}</small>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <span className="p-0 cursor-pointer custom-button-style">
                      ...
                    </span>
                  </OverlayTrigger>
                </td>

                <td className="text-center text-nowrap">
                  {timeConvert(item["startDateTime"])}
                </td>
                <td className="text-center">
                  {durationConvert(item["duration"])}{" "}
                </td>
                <td className="text-center">{item["user"]}</td>
                <td className="d-flex text-nowrap">
                  {statusConvert(item["status"])}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Modal
        id="create-document-modal"
        onHide={() => setShowModal(false)}
        show={showModal}
        title=""
        modalButtons={modalButtons}
        backdrop="static"
      >
        <div>Are you sure you want to delete this PDF documentation?</div>
      </Modal>

      <MultiplePDFDownloadModal
        isOpen={isModalOpen}
        modalData={modalData}
        onClose={handleModalClose}
        onDownloadClick={handleMultipleDownload}
      />
    </>
  );
};

export default PDFDashboardTable;
