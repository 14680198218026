import React from 'react';
import { FormLabel, FormSelect } from 'react-bootstrap';
import { classLister } from '../../utils/style';
import style from './index.module.css';

const Select = ({
  id,
  className,
  customClassNames,
  options,
  disabled,
  size,
  wrapperClasses,
  value,
  defaultValue,
  onChange,
  label,
  showDefaultValue
}) => {
  const customClasses = classLister(style)(customClassNames);
  const customWrapperClasses = classLister(style)(wrapperClasses);

  return (
    <div className={`d-flex flex-column ${customWrapperClasses}`}>
      <FormLabel className={`${style.custom_label}`} htmlFor={id}>
        {label}
      </FormLabel>
      <FormSelect
        id={id}
        className={`${className || ''} ${customClasses}`}
        disabled={disabled}
        size={size}
        value={value}
        onChange={onChange}
      >
        {
          defaultValue ? <option key="placeholder" hidden={!showDefaultValue}>{defaultValue}</option> : null
        }
        {
          options.map((option, index) =>
            <option key={index}>{option}</option>
          )
        }
      </FormSelect>
    </div>
  );
};

Select.defaultProps = {
  label: '',
  disabled: false,
  customClassNames: [],
  wrapperClasses: []
};

export default Select;
