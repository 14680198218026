import React from 'react';
import { Modal as BootstrapModal, ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { classLister } from '../../utils/style';
import style from './index.module.css';

const Modal = ({
  id,
  className,
  customClassNames,
  onHide,
  size,
  show,
  title,
  children,
  modalButtons,
  backdrop
}) => {
  const customClasses = classLister(style)(customClassNames);

  return (
    <BootstrapModal
      id={id}
      className={`${className || ''} ${customClasses}`}
      show={show}
      onHide={onHide}
      size={size}
      centered
      backdrop={backdrop}
    >
      <ModalHeader closeButton>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {modalButtons}
      </ModalFooter>
    </BootstrapModal>
  );
};

Modal.defaultProps = {
  size: '',
  customClassNames: []
};

export default Modal;
