import React, { createContext, useContext, useState, useEffect } from 'react';

const CookieConsentContext = createContext();

export const useCookieConsent = () => useContext(CookieConsentContext);

export const CookieConsentProvider = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useState(localStorage.getItem("cookieConsent"));
  const [functionalCookiesConsent, setFunctionalCookiesConsent] = useState(true);

  useEffect(() => {
    localStorage.setItem("cookieConsent", String(cookieConsent));
    localStorage.setItem("functionalCookies", String(functionalCookiesConsent));
  }, [cookieConsent, functionalCookiesConsent]);

  return (
    <CookieConsentContext.Provider value={{
      cookieConsent,
      setCookieConsent,
      functionalCookiesConsent,
      setFunctionalCookiesConsent
    }}>
      {children}
    </CookieConsentContext.Provider>
  );
};
