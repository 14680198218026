import React, { useState, useEffect } from "react";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import headerStyle from "../Header/index.module.css";
import Select from "../Select";
import { formatUrl, get } from "../../utils/api";
import { useTargetSystemContext } from "../../contexts/TargetSystemContext";

const Header = ({ title, selectedOrganization, setSelectedOrganization }) => {

  const { targetSystem, setTargetSystem } = useTargetSystemContext();

  const [organizations, setOrganizations] = useState([]);
  const [targetSystems, setTargetSystems] = useState([]);
  const [organizationsDetails, setOrganizationsDetails] = useState([])
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useDocumentTitle(`${title}`);

  useEffect(() => {
    const fetchUserOrganizationData = async () => {

      const response = await get("user/organization");
      
      const orgs = response?.data?.map((item) =>{
      return {
        organizationName: item.organizationName,
        targetSystems: item.targetSystems,
        defaultSystem: item.defaultSystem,
      }});

      setOrganizationsDetails(orgs)
      const targetOrganizationName = response?.data?.find((item) => item.organizationName === selectedOrganization) || orgs[0]

      if (targetOrganizationName) {
        const url = formatUrl("user/profile", {
          organizationName: targetOrganizationName.organizationName,
        });

        const responseProfile = await get(url);
        setFirstName(responseProfile.data[1].firstName);
        setLastName(responseProfile.data[1].lastName);
        setOrganizations(orgs.map(org => org.organizationName));
        setSelectedOrganization(targetOrganizationName.organizationName);
        setTargetSystem(targetOrganizationName.defaultSystem);
        setTargetSystems(targetOrganizationName.targetSystems);
      }
    };

    fetchUserOrganizationData();
  }, [selectedOrganization, setSelectedOrganization, setTargetSystem]);

  const changeSelectedOrganization = (organization) => {
    setSelectedOrganization(organization)
    const foundOrganization = organizationsDetails.find(org => org.organizationName === organization)
    if (foundOrganization) {
      setTargetSystem(foundOrganization.defaultSystem)
      setTargetSystems(foundOrganization.targetSystems)
    }
  }

  return (
    <>
      <div className={headerStyle.header_container}>
        <div>
          <span className={headerStyle.header_user}>
            Hi, {firstName} {lastName}
          </span>
          <div className={headerStyle.header_main}>{title}</div>
        </div>
        <div className={headerStyle.header__dropdowns}>
          <div>
            <span className={headerStyle.header_user}>Target system</span>
            <Select
              id="targetSystem"
              size="sm"
              options={targetSystems}
              customClassNames={["custom_select"]}
              defaultValue={targetSystem}
              value={targetSystem}
              onChange={(e) => setTargetSystem(e.target.value)}
            />
          </div>
          <div>
            <span className={headerStyle.header_user}>Workplace</span>
            <Select
              id="organization"
              size="sm"
              options={organizations}
              customClassNames={["custom_select"]}
              defaultValue={selectedOrganization}
              onChange={(e) => changeSelectedOrganization(e.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

Header.defaultProps = {
  title: "",
};

export default Header;
