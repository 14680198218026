import { createContext, useContext } from "react";

export const ProjectsContext = createContext({
  projects: [],
  setProjects: () => {},
  selectedProjectSpace: "",
  setSelectedProjectSpace: () => {},
  selectedProjects: [],
  setSelectedProjects: () => {},
  projectsWithConfigFiles: [],
  setProjectsWithConfigFiles: () => {},
  selectedProjectsWithConfigFiles: [],
  setSelectedProjectsWithConfigFiles: () => {},
  storeConfigFileLocationOnly: Boolean,
  setStoreConfigFileLocationOnly: () => {},
  startsWithFilter: "",
  setStartsWithFilter: () => {},
  containsFilter: "",
  setContainsFilter: () => {},
});

export const useProjects = () => useContext(ProjectsContext);
