import React from "react";

function ChangesToPrivacyNotice() {
  return (
    <div className="mt-5">
      <h4>Changes to Privacy Notice</h4>
      <p>
        We may update this Privacy Notice to reflect changes in our practices or for other operational, legal, or regulatory reasons. The updated Privacy Notice will be posted on our website, and the date of the latest revision will be indicated at the beginning of the Privacy Notice.
      </p>
      <p>
        Thank you for using Pete - Virtual Assistant for SnapLogic
        Documentation.
      </p>
    </div>
  );
}

export default ChangesToPrivacyNotice;
