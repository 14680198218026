import Button from "./Button";
import FooterLogo from "./UI/FooterLogo/FooterLogo";
import Logo from "./UI/Logo/Logo";
import { useNavigate } from "react-router-dom";

const PageNotFound = ({ text }) => {
    const navigate = useNavigate();

    return (
        <div className="container-fluid">
            <div className="row no-gutter">

                <div className="col-md-6 bg-light">
                    <div className={`wrapper d-flex align-items-center py-5`}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 col-xl-7 mx-auto">
                                    <Logo />
                                    <h3 className="display-4 my-4">Page not found</h3>
                                    <p className="text-muted">Sorry, the page you were looking for doesn't exist.</p>
                                    {text && <p className="text-danger" >{text}</p>}
                                    <Button className="w-100" onClick={() => navigate("/login")}>
                                        Return To Login
                                    </Button>
                                </div>
                                <FooterLogo />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`col-md-6 d-none d-md-flex bg_image`}></div>
            </div>
        </div>
    );
};

export default PageNotFound;
