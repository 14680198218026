export const CREATE_DOCUMENT_TABS = [
  {
    eventKey: 'snaplogic',
    value: '1 Setup SnapLogic'
  },
  {
    eventKey: 'confluence',
    value: '2 Setup Confluence'
  },
  {
    eventKey: 'create',
    value: '3 Create'
  }
];

export const CREATE_DOCUMENT_SHAREPOINT_TABS = [
  {
    eventKey: 'snaplogic',
    value: '1 Setup SnapLogic'
  },
  {
    eventKey: 'confluence',
    value: '2 Setup Sharepoint'
  },
  {
    eventKey: 'create',
    value: '3 Create'
  }
];
