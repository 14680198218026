export const durationConvert = (duration) => {
  const totalSeconds = parseInt(duration, 10);
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = totalSeconds - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds;
};

export const timeConvert = (time) => {
  return new Date(time).toLocaleString();
};

export const statusConvert = (status) => {
  if (status === 0) {
    return (
      <>
        <div>In progress</div>
        <div className="ms-auto">⚙️</div>
      </>
    );
  }
  if (status === 1) {
    return (
      <>
        <div>Completed</div>
        <div className="ms-auto">✅</div>
      </>
    );
  }
  if (status === 2) {
    return (
      <>
        <div>Failed </div>
        <div className="ms-auto">❌</div>
      </>
    );
  }
  return status;
};
