import React from "react";
import style from "./index.module.css";

function HowWeUseYourPersonalData() {
  return (
    <div className="mt-5">
      <h4>Why we process your Personal Data </h4>
      <p>
        We collect and use the Personal Data described above to provide you with the Services in a reliable and secure manner. We also collect and use Personal Data for our legitimate business needs. To the extent we process your Personal Data for other purposes, we ask for your consent in advance. The variety of purposes include:  </p>
      <ul className={style["disc-list"]}>
        <li>
          <strong>Providing Services or Information Requested.</strong> Pete may
          use information about you to fulfill requests for Services or
          information, including to:
          <ul>
            <li>Manage Individual information and Service accounts;</li>
            <li>Respond to questions, comments, and other requests;</li>
            <li>Contact you to answer requests for support;</li>
            <li>Allow you to register for events.</li>
          </ul>
        </li>

        <li>
          <strong>Administrative Purposes.</strong> Our Service may use Personal
          Data about you for its administrative purposes, including to:
          <ul>
            <li>Measure interest in Pete’s Service;</li>
            <li>Develop new Services;</li>
            <li>Verify Individual identity;</li>
            <li>Communicate about changes to Pete Notice;</li>
            <li>
              Send email to the email address you provide to us to verify your
              account and for informational and operational purposes, such as
              account management;
            </li>
            <li>Prevent potentially prohibited or illegal activities;</li>
          </ul>
        </li>

        <li>
          <strong>Marketing Pete. </strong>Our marketing team may use your
          information, including Personal Data to provide you with materials
          about{" "}
          <a
            href="https://iwconnect.com/products/pete/"
            target="_blank"
            rel="noreferrer"
          >
            offers
          </a>{" "}
          by email, as permitted by applicable law. Such uses include:
          <ul>
            <li>To tailor content, advertisements, and offers;</li>
            <li>
              For other purposes disclosed at the time that you provide Personal
              Data; or
            </li>
            <li>Otherwise with your consent.</li>
          </ul>
        </li>

        <li>
          <strong>Research and Development.</strong> Pete may use Personal Data
          alone or in the aggregate with information obtained from other sources
          (e.g. web forms) to
          <ul>
            <li>Help us to optimally deliver our existing Services,</li>
            <li>Better understand and serve the users of the Services,</li>
            <li>
              Conduct research on customer demographics, interests and behavior;
              and
            </li>
            <li>Develop new Services.</li>
          </ul>
          <p>
            This research may be compiled and analyzed alone or on an aggregate
            basis, and Pete may share the aggregate anonymized data with its
            current affiliates.
          </p>
        </li>

        <li>
          <strong>Other Uses.</strong> Pete may use Personal Data for which we
          have a legitimate interest, such as direct marketing, individual or
          market research, fraud prevention, disclosure to our affiliated
          organizations and network and information security. We may also use
          your Personal Data where such use is necessary to provide the Service,
          to comply with our legal obligations or for any other purpose
          disclosed to you at the time you provide Personal Data or with your
          consent.
        </li>
      </ul>
    </div>
  );
}

export default HowWeUseYourPersonalData;
