const routesNav = {
  pdf: [
    {
      section: "1",
      title: "Useful links",
      items: [
        {
          title: "New Document",
          path: "/new",
          image: "assets/images/menu/newdoc.svg",
        },
        {
          title: "Dashboard",
          path: "/dashboard",
          image: "assets/images/menu/dashboard.svg",
        },
      ],
    },
    {
      section: "2",
      title: "Help",
      items: [
        {
          title: "Help",
          path: "/help",
          image: "assets/images/menu/help.svg",
        },
        {
          title: "Contact Us",
          path: "/contact",
          image: "assets/images/menu/contact.svg",
        },
      ],
    },
    {
      section: "3",
      title: "My Profile",
      items: [
        {
          title: "My Profile",
          path: "/profile",
          image: "assets/images/menu/profile.svg",
        },
        {
          title: "Users",
          path: "/users",
          image: "assets/images/menu/people.svg"
        },
        {
          title: "Logout",
          path: "/logout",
          image: "assets/images/menu/log out icon.svg",
        },
      ],
    },
  ],

  confluence: [
    {
      section: "1",
      title: "Useful links",
      items: [
        {
          title: "New Document",
          path: "/new",
          image: "assets/images/menu/newdoc.svg",
        },
        {
          title: "Dashboard",
          path: "/dashboard",
          image: "assets/images/menu/dashboard.svg",
        },
        {
          title: "Confluence",
          path: "/confluence",
          image: "assets/images/menu/confluence.svg",
        },
        {
          title: "Organization Summary",
          path: "/summary",
          image: "assets/images/menu/summaryOrganizationIcon.svg",
        },
      ],
    },
    {
      section: "2",
      title: "Help",
      items: [
        {
          title: "Help",
          path: "/help",
          image: "assets/images/menu/help.svg",
        },
        {
          title: "Contact Us",
          path: "/contact",
          image: "assets/images/menu/contact.svg",
        },
      ],
    },
    {
      section: "3",
      title: "My Profile",
      items: [
        {
          title: "My Profile",
          path: "/profile",
          image: "assets/images/menu/profile.svg",
        },
        {
          title: "Users",
          path: "/users",
          image: "assets/images/menu/people.svg"
        },
        {
          title: "Logout",
          path: "/logout",
          image: "assets/images/menu/log out icon.svg",
        },
      ],
    },
  ],

  sharepoint: [
    {
      section: "1",
      title: "Useful links",
      items: [
        {
          title: "New Document",
          path: "/new",
          image: "assets/images/menu/newdoc.svg",
        },
        {
          title: "Dashboard",
          path: "/dashboard",
          image: "assets/images/menu/dashboard.svg",
        },
        {
          title: "Sharepoint",
          path: "/sharepoint",
          image: "assets/images/menu/confluence.svg",
        }
      ],
    },
    {
      section: "2",
      title: "Help",
      items: [
        {
          title: "Help",
          path: "/help",
          image: "assets/images/menu/help.svg",
        },
        {
          title: "Contact Us",
          path: "/contact",
          image: "assets/images/menu/contact.svg",
        },
      ],
    },
    {
      section: "3",
      title: "My Profile",
      items: [
        {
          title: "My Profile",
          path: "/profile",
          image: "assets/images/menu/profile.svg",
        },
        {
          title: "Users",
          path: "/users",
          image: "assets/images/menu/people.svg"
        },
        {
          title: "Logout",
          path: "/logout",
          image: "assets/images/menu/log out icon.svg",
        },
      ],
    },
  ],
};

export default routesNav;
