import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Menu/index";
import layoutStyle from "./index.module.css";
import Header from "../Header";
import { SelectedOrganizationContext } from "../../contexts/SelectedOrganizationContext";
import { ProjectsContext } from "../../contexts/ProjectsContext";
import { PAGES } from "../../config/pages";
import pagesWithNoLayout from "../../constants/pagesWithNoLayout";
import { TargetSystemContext } from "../../contexts/TargetSystemContext";

const Layout = ({ children }) => {
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProjectSpace, setSelectedProjectSpace] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [projectsWithConfigFiles, setProjectsWithConfigFiles] = useState([]);
  const [selectedProjectsWithConfigFiles, setSelectedProjectsWithConfigFiles] =
    useState([]);

  const [startsWithFilter, setStartsWithFilter] = useState("");
  const [containsFilter, setContainsFilter] = useState("");

  const [storeConfigFileLocationOnly, setStoreConfigFileLocationOnly] =
    useState(false);

  // TODO ADD LOGIC TO change to Confluence if needed
  const [targetSystem, setTargetSystem] = useState("PDF");

  const { pathname } = useLocation();
  const page = PAGES.find((obj) => obj.path === pathname);
  let navbar = null;
  let header = null;

  if (pathname !== "/") {
    navbar = <Navbar className={layoutStyle.nav_width} />;
    header = (
      <Header
        title={page && page.title ? page.title : ""}
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
      />
    );
  }

  if (pagesWithNoLayout.includes(pathname)) {
    navbar = null;
    header = null;
  }

  return (
    <SelectedOrganizationContext.Provider value={selectedOrganization}>
      <TargetSystemContext.Provider value={{ targetSystem, setTargetSystem }}>
        <ProjectsContext.Provider
          value={{
            projects,
            setProjects,
            selectedProjectSpace,
            setSelectedProjectSpace,
            selectedProjects,
            setSelectedProjects,
            projectsWithConfigFiles,
            setProjectsWithConfigFiles,
            selectedProjectsWithConfigFiles,
            setSelectedProjectsWithConfigFiles,
            storeConfigFileLocationOnly,
            setStoreConfigFileLocationOnly,
            startsWithFilter,
            setStartsWithFilter,
            containsFilter,
            setContainsFilter,
          }}
        >
          <div
            className={`${
              header === null ? layoutStyle.noheader : layoutStyle.responsive
            }`}
          >
            {navbar}
            <div className={header === null ? null : layoutStyle.main_width}>
              {header}
              <main className="main-in-layout">{children}</main>
            </div>
          </div>
        </ProjectsContext.Provider>
      </TargetSystemContext.Provider>
    </SelectedOrganizationContext.Provider>
  );
};

export default Layout;
