import React from "react";
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import { durationConvert, timeConvert, statusConvert } from "./tableHelpers";
import SharepointTableHeader from "./SharepointTableHeader";

const SharepointDashboardTable = ({ projectsData }) => {
  return (
    <Table hover responsive className="mt-4 font-14px">
      <SharepointTableHeader />
      <tbody>
        {projectsData &&
          projectsData.map((item) => (
            <tr key={item["ID"]}>
              <td>
                {item["projectName"].length > 20
                  ? item["projectName"].slice(0, 21)
                  : item["projectName"]}
                ;
                {item["projectName"].length > 20 && (
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    key={item["ID"]}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-${item["ID"]}`}>
                        <Popover.Header
                          className="fs-6 fw-bold custom-bg"
                        >
                          {item["projectName"]} Project Name
                        </Popover.Header>
                        <Popover.Body>
                          <small>{item["projectName"]}</small>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <span className="p-0 cursor-pointer custom-button-style">
                      ...
                    </span>
                  </OverlayTrigger>
                )}
              </td>
              <td className="text-center">
                <a href={item["confluencePageURL"]} target="_blank">
                  {item["confleunceMainPage"]}
                </a>
              </td>
              <td className="text-center">
                {item["ID"].slice(0, 10)}
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  key={item["ID"]}
                  placement={"top"}
                  overlay={
                    <Popover id={`popover-${item["ID"]}`}>
                      <Popover.Header
                        className="popover-header"
                      >
                        {item["projectName"]} Execution ID
                      </Popover.Header>
                      <Popover.Body>
                        <small>{item["ID"]}</small>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <span className="p-0 cursor-pointer custom-button-style">...</span>
                </OverlayTrigger>
              </td>

              <td className="text-center text-nowrap">
                {timeConvert(item["startDateTime"])}
              </td>
              <td className="text-center">
                {durationConvert(item["duration"])}{" "}
              </td>
              <td className="text-center">{item["user"]}</td>
              <td className="d-flex text-nowrap">
                {statusConvert(item["status"])}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default SharepointDashboardTable;
