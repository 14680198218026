import React from 'react';
import { Container } from 'react-bootstrap';
import FAQ from '../Help/Faq';

const HelpPage = () => {
  return (
    <Container as={"main"} fluid className="px-5">
      <FAQ />
    </Container>
  )
}

export default HelpPage