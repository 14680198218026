import React from "react";
import InfoPageHeader from "../../components/InfoPages/Header";
import HowPeteWorksContent from "../../components/HowPeteWorksContent";

function HowPeteWorks() {
  return (
    <div className="w-75 mx-auto">
      <InfoPageHeader />
      <HowPeteWorksContent />
    </div>
  );
}

export default HowPeteWorks;
