import React from "react";
import style from "./index.module.css";

function ContactInformation() {
  return (
    <div className="mt-5">
      <h4>Contact information</h4>
      <p>
        If you have any questions regarding the processing of your personal information, requests for your rights set out in this Privacy Statement, or if you would like to give us feedback on our Privacy Statement and / or our Privacy Practices, please contact us:
      </p>
      <table className={style["table"]}>
        <tr>
          <th className={style["cell"]}>Controller</th>
          <td className={style["cell"]}>INTERVORKS DOOEL
            <br />
            E-mail: <a href="mailto:legal@iwconnect.com">legal@iwconnect.com</a>
            <br />
            Address: Metodija Andonov Chento no.15, 7000 Bitola
            <br />
            Republic of North Macedonia
            <br />
            Tel: 047 221 914
          </td>
        </tr>
        <tr>
          <th className={style["cell"]}>Data Protection Officer</th>
          <td className={style["cell"]}><a href="mailto:privacy@iwconnect.com">privacy@iwconnect.com</a></td>
        </tr>
      </table>

    </div>
  );
}

export default ContactInformation;
