import React from "react";

function HowWeCollectData() {
  return (
    <div className="mt-5">
      <h4>How we collect data</h4>
      <p>We may collect your Personal Data on different ways: </p>
      <ol>
        <li>
          You may provide your Personal Data <strong>directly to us</strong> by interacting with Pete as a Service or when you request information via email or the “Contact us” form in the Service.
        </li>
        <li>
          <p>
            Information we collect from <strong>third parties</strong>: From time to time, we may obtain information about you from our third-party sources as required or permitted by applicable law. These sources may include:
          </p>
        </li>
        <li>
          <strong>Joint Marketing Partners & Resellers: </strong> For example, unless prohibited by applicable law, our joint marketing partners or resellers may share information about you with us for demo purposes so that we can work together to setup trial environment or to better understand which of our package may be of interest to you. How our partners and resellers collect, store, disclose, and use information they collect should be described in their Privacy Notice. If we receive any Personal Data from our partners or resellers, we will look for the consent of the users that the data can be used for the purpose of Pete’s application. If we do not receive any consent, we will not process the data delivered to us.
        </li>
        <li><strong>Other Sources: </strong>We may obtain information about you from other sources such as data 	analytics providers, fraud prevention service providers, vendors that provide services on our 	behalf (SnapLogic and other resellers), or publicly available sources. </li>
      </ol>
    </div>
  );
}

export default HowWeCollectData;
