const pagesWithNoLayout = [
  "/resetpass",
  "/forgotpass",
  "/login",
  "/privacy-notice",
  "/how-pete-works",
  "/how-to-setup",
  "/how-to-setup-using-sharepoint",
  "/privacy-notice-history"
];

export default pagesWithNoLayout;
